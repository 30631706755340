import Permission, { Operation } from './Permission';

export default class UserPermission {
  private permission: Permission;

  currentPermissions?: string[];

  userRole?: string;

  constructor(permission: Permission) {
    this.permission = permission;
  }

  setPermission(userPermissions: string[]) {
    const temp: string[] = [];

    userPermissions.forEach((up) => {
      if (!this.permission.permissions[up]) throw new Error(`Permission ${up} not found.`);
      temp.push(up as keyof Permission['permissions']);
    });

    this.currentPermissions = [...temp];
  }

  setRole(userRole: string) {
    this.userRole = userRole;
  }

  isAllowed(resourceName: string, operation: Operation): boolean {
    if (this.userRole === 'OWNER') return true;
    if (!this.currentPermissions) throw new Error('No permissions has been setted.');
    return this.permission.isAllowed(this.currentPermissions, resourceName, operation);
  }
}
