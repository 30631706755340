import { Navigate, useLocation } from 'react-router-dom';
import { PATH_BUSINESS, PATH_DASHBOARD, PATH_PAGE } from 'src/routes/paths';
import LoadingScreen from '../components/loading-screen';

import { useBusinessContext } from './useBusinessContext';

type BusinessGuardProps = {
  children: React.ReactNode;
};

export default function BusinessGuard({ children }: BusinessGuardProps) {
  const { business, isInitialized, error } = useBusinessContext();

  const { pathname } = useLocation();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (isInitialized && error) {
    return <Navigate to={PATH_PAGE.page500} />;
  }

  if (!business && pathname !== PATH_BUSINESS.setup) {
    return <Navigate to={PATH_BUSINESS.setup} />;
  }

  if (business && !business?.email && pathname !== PATH_BUSINESS.contactSetup) {
    return <Navigate to={PATH_BUSINESS.contactSetup} />;
  }

  if (business && business.email && pathname.startsWith('/business')) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <> {children} </>;
}
