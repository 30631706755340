import { gql } from '@apollo/client';

export default gql`
  mutation updateBusiness(
    $id: String!
    $name: String
    $domain: String
    $email: String
    $phoneNumber: String
    $about: String
    $googleAnalyticsId: String
    $facebookUrl: String
    $instagramUrl: String
  ) {
    updateBusiness(
      id: $id
      name: $name
      domain: $domain
      email: $email
      phoneNumber: $phoneNumber
      about: $about
      googleAnalyticsId: $googleAnalyticsId
      facebookUrl: $facebookUrl
      instagramUrl: $instagramUrl
    ) {
      id
      name
      domain
      ownerId
      businessType
      email
      phoneNumber
      about
      logoUrl
      googleAnalyticsId
      facebookUrl
      instagramUrl
      employees {
        id
      }
      locations {
        id
        name
        isDefault
      }
    }
  }
`;
