import { gql } from '@apollo/client';

export default gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      firstName
      lastName
      email
      role
      permissions
      businessId
    }
  }
`;
