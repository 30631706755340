import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IWorkingHoursState } from '../../@types/workingHours';
import { workingHours } from '../../_mock/assets/workingHours';

// ----------------------------------------------------------------------

const initialState: IWorkingHoursState = {
  isLoading: false,
  error: null,
  data: null,
};

const slice = createSlice({
  name: 'workingHours',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getWorkingHoursSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getWorkingHours() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      setTimeout(() => {
        dispatch(slice.actions.getWorkingHoursSuccess(workingHours));
      }, 300);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
