import { useContext } from 'react';
import { BusinessContext } from './BusinessContext';

export const useBusinessContext = () => {
  const context = useContext(BusinessContext);

  if (!context) throw new Error('useBusinessContext context must be use inside BusinessProvider');

  return context;
};
