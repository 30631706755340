/* eslint-disable import/no-extraneous-dependencies */
import { Divider, Stack } from '@mui/material';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';

import { useAuthContext } from '../../auth/useAuthContext';

type Props = {
  token?: string | null;
  isInvitation?: boolean;
};

export default function AuthWithSocial({ token: verificationToken, isInvitation }: Props) {
  const { loginWithGoogle, acceptInvitationWithGoogle } = useAuthContext();

  const handleGoogleLogin = async (response: CredentialResponse) => {
    try {
      if (isInvitation && verificationToken && acceptInvitationWithGoogle && response.credential) {
        acceptInvitationWithGoogle(verificationToken, response.credential);
      }

      if (!isInvitation && loginWithGoogle && response.credential) {
        loginWithGoogle(response.credential);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Divider
        sx={{
          my: 2.5,
          typography: 'overline',
          color: 'text.disabled',
          '&::before, ::after': {
            borderTopStyle: 'dashed',
          },
        }}
      >
        OR
      </Divider>

      <Stack direction="row" justifyContent="center" spacing={2}>
        <GoogleLogin
          ux_mode="popup"
          onSuccess={(response) => {
            handleGoogleLogin(response);
          }}
        />
      </Stack>
    </div>
  );
}
