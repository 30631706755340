import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { hasFileToUpload } from '../utils/fileUpload';
import { useAuthContext } from '../auth/useAuthContext';
import axios from '../utils/axios';
import CREATE_BUSINESS from '../graphql/mutations/createBusiness';
import GET_BUSINESS from '../graphql/queries/getBusiness';
import SET_BUSINESS_CONTACT from '../graphql/mutations/setBusinessContact';
import UPDATE_BUSINESS from '../graphql/mutations/updateBusiness';
import { IBusinessAddress, IBusiness } from '../@types/business';
import {
  ActionMapType,
  BusinessContextType,
  BusinessGeneralInfoType,
  BusinessStateType,
} from './types';

enum Types {
  INITIAL = 'INITIAL',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
  BUSINESS_SETUP = 'BUSINESS_SETUP',
  BUSINESS_UPDATE = 'BUSINESS_UPDATE',
  ERROR = 'ERROR',
}

type Payload = {
  [Types.INITIAL]: {
    business: IBusiness;
  };
  [Types.BUSINESS_SETUP]: {
    business: IBusiness;
  };
  [Types.BUSINESS_UPDATE]: {
    business: IBusiness;
  };
  [Types.NOT_AUTHENTICATED]: {
    business: IBusiness;
  };
  [Types.ERROR]: {
    error: boolean;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

type UploadResult = string | null;

const initialState: BusinessStateType = {
  isInitialized: false,
  business: null,
  error: false,
};

const reducer = (state: BusinessStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      business: action.payload.business,
      error: false,
    };
  }
  if (action.type === Types.NOT_AUTHENTICATED) {
    return {
      isInitialized: false,
      business: null,
      error: false,
    };
  }
  if (action.type === Types.BUSINESS_SETUP) {
    return {
      ...state,
      business: action.payload.business,
      error: false,
    };
  }
  if (action.type === Types.BUSINESS_UPDATE) {
    return {
      ...state,
      business: action.payload.business,
      error: false,
    };
  }
  if (action.type === Types.ERROR) {
    return {
      isInitialized: true,
      business: null,
      error: true,
    };
  }
  return state;
};

export const BusinessContext = createContext<BusinessContextType | null>(null);

type BusinessProviderProps = {
  children: React.ReactNode;
};

export function BusinessProvider({ children }: BusinessProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isAuthenticated, user, updateUsersBusiness } = useAuthContext();

  const client = useApolloClient();

  const initialize = useCallback(async () => {
    try {
      if (isAuthenticated) {
        if (user && user.businessId) {
          const { businessId } = user;

          const { data } = await client.query({
            query: GET_BUSINESS,
            variables: { id: businessId },
          });
          const business = data?.getBusiness;

          if (business) {
            dispatch({
              type: Types.INITIAL,
              payload: {
                business,
              },
            });
          } else {
            dispatch({
              type: Types.INITIAL,
              payload: {
                business: null,
              },
            });
          }
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              business: null,
            },
          });
        }
      } else {
        dispatch({
          type: Types.NOT_AUTHENTICATED,
          payload: {
            business: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: Types.ERROR,
        payload: {
          error: true,
        },
      });
    }
  }, [isAuthenticated, user, client]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const setupBusiness = useCallback(
    async (name: string, domain: string, businessType: string) => {
      const ownerId = user!.id;
      const { data } = await client.mutate({
        mutation: CREATE_BUSINESS,
        variables: { name, domain, businessType, ownerId },
      });

      const business = data.createBusiness;

      if (business) {
        updateUsersBusiness(business.id);
      }

      dispatch({
        type: Types.BUSINESS_SETUP,
        payload: {
          business,
        },
      });
    },
    [client, user, updateUsersBusiness]
  );

  const setBusinessContact = useCallback(
    async (email: string, phoneNumber: string | null) => {
      const id = state.business?.id;
      if (id) {
        const { data } = await client.mutate({
          mutation: SET_BUSINESS_CONTACT,
          variables: { id, email, phoneNumber },
        });

        const business = data.setBusinessContact;

        dispatch({
          type: Types.BUSINESS_UPDATE,
          payload: {
            business,
          },
        });
      }
    },
    [client, state.business]
  );

  const handleFileUpload = useCallback(
    async (file: any, businessId: string): Promise<UploadResult> => {
      if (file && businessId) {
        const formData = new FormData();
        formData.append('file', file);

        const { data: uploadData } = await axios({
          method: 'post',
          url: `/api/businesses/upload/${businessId}`,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (uploadData.logoUrl) {
          return uploadData.logoUrl;
        }
      }
      return null;
    },
    []
  );

  const updateBusinessGeneralInfo = useCallback(
    // eslint-disable-next-line consistent-return
    async (updatedBusiness: Partial<BusinessGeneralInfoType>): Promise<void> => {
      const id = state.business?.id;

      if (id) {
        const { logoUrl, ...rest } = updatedBusiness;

        let data: { logoUrl?: string; address?: IBusinessAddress | null } = {};

        if (rest && Object.keys(rest).length >= 1) {
          const { data: businessData } = await client.mutate({
            mutation: UPDATE_BUSINESS,
            variables: { id, ...rest },
          });

          data = businessData.updateBusiness;
        } else {
          data = { ...state.business };
        }

        if (logoUrl && hasFileToUpload(logoUrl)) {
          const newLogoUrl = await handleFileUpload(logoUrl, id);

          if (newLogoUrl) {
            data.logoUrl = newLogoUrl;
          }
        }

        dispatch({
          type: Types.BUSINESS_UPDATE,
          payload: {
            business: {
              ...data,
              locations: state.business?.locations ? [...state.business.locations] : [],
            },
          },
        });
      }
    },
    [state.business, client, handleFileUpload]
  );

  // TODO: delete or change to billing address
  const updateBusinessAddress = useCallback(
    async (addressUpdates: Partial<IBusinessAddress>): Promise<void> => {
      const businessId = state.business?.id;

      if (!businessId) {
        throw new Error('businessId is required');
      }

      const id = state.business?.address?.id;

      if (id) {
        const { data } = await axios.patch(`/business/address/${id}`, {
          ...addressUpdates,
        });

        dispatch({
          type: Types.BUSINESS_UPDATE,
          payload: {
            business: {
              ...state.business,
              locations: state.business?.locations ? [...state.business.locations] : [],
              address: { ...data },
            },
          },
        });
      } else {
        const { data } = await axios.post(`/business/address/${businessId}`, {
          ...addressUpdates,
        });

        dispatch({
          type: Types.BUSINESS_UPDATE,
          payload: {
            business: {
              ...state.business,
              locations: state.business?.locations ? [...state.business.locations] : [],
              address: { ...data },
            },
          },
        });
      }
    },
    [state.business]
  );

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      business: state.business,
      error: state.error,
      setupBusiness,
      setBusinessContact,
      updateBusinessGeneralInfo,
      updateBusinessAddress,
    }),
    [
      state.isInitialized,
      state.business,
      state.error,
      setupBusiness,
      setBusinessContact,
      updateBusinessGeneralInfo,
      updateBusinessAddress,
    ]
  );

  return <BusinessContext.Provider value={memoizedValue}>{children}</BusinessContext.Provider>;
}
