import Permission from './Permission';

export const Permissions = {
  edit_business: 'edit_business',
  edit_billing: 'edit_billing',
  view_locations: 'view_locations',
  edit_locations: 'edit_locations',
  delete_locations: 'delete_locations',
  view_own_reservations: 'view_own_reservations',
  edit_own_reservations: 'edit_own_reservations',
  delete_own_reservations: 'delete_own_reservations',
  view_all_reservations: 'view_all_reservations',
  edit_all_reservations: 'edit_all_reservations',
  delete_all_reservations: 'delete_all_reservations',
  add_new_employee: 'add_new_employee',
  view_other_employees: 'view_other_employees',
  edit_other_employees: 'edit_other_employees',
  delete_other_employees: 'delete_other_employees',
};

export enum Resources {
  user = 'user',
  reservation = 'reservation',
}

const acl = new Permission();

Object.keys(Permissions).forEach((p) => {
  acl.addPermission(p);
});

Object.keys(Resources).forEach((r) => {
  acl.addResource(r);
});

acl.allow(Permissions.view_other_employees, Resources.user, 'view');
acl.allow(Permissions.edit_other_employees, Resources.user, 'edit');
acl.allow(Permissions.delete_other_employees, Resources.user, 'delete');
acl.allow(Permissions.add_new_employee, Resources.user, 'create');

export default acl;
