import { gql } from '@apollo/client';

export default gql`
  mutation loginWithGoogle($token: String!) {
    loginWithGoogle(token: $token) {
      id
      firstName
      lastName
      email
      role
      permissions
      businessId
    }
  }
`;
