import { useAuthContext } from 'src/auth/useAuthContext';
import LoadingScreen from 'src/components/loading-screen';
import { Provider } from './Context';
import Permission from './Permission';

export type Props = {
  permission: Permission;
  children: React.ReactNode;
};

const AclProvider = ({ permission, children }: Props): JSX.Element => {
  const { user, isInitialized } = useAuthContext();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  const userPermissions = user?.permissions || [];
  const userRole = user?.role || 'REGULAR';

  return (
    <Provider permission={permission} userPermissions={userPermissions} userRole={userRole}>
      {children}
    </Provider>
  );
};

export default AclProvider;
