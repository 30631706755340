// i18n
import './locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import './utils/mapboxgl';
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
// redux
import { store, persistor } from './redux/store';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import { StyledChart } from './components/chart';
import SnackbarProvider from './components/snackbar';
import ScrollToTop from './components/scroll-to-top';
import { MotionLazyContainer } from './components/animate';
import { ThemeSettings, SettingsProvider } from './components/settings';
import { API_BASE_URL, GOOGLE_AUTH_CLIENT_ID } from './config-global';

// Check our docs
// https://docs.minimals.cc/authentication/ts-version

import { AuthProvider } from './auth/SessionContext';
import { BusinessProvider } from './business/BusinessContext';
import AclProvider from './acl/Provider';
import acl from './acl/acl';

const client = new ApolloClient({
  uri: `${API_BASE_URL}/graphql`,
  cache: new InMemoryCache(),
  credentials: 'include',
});

export default function App() {
  return (
    <ApolloProvider client={client}>
      <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
        <AuthProvider>
          <BusinessProvider>
            <HelmetProvider>
              <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SettingsProvider>
                      <BrowserRouter>
                        <AclProvider permission={acl}>
                          <ScrollToTop />
                          <MotionLazyContainer>
                            <ThemeProvider>
                              <ThemeSettings>
                                <ThemeLocalization>
                                  <SnackbarProvider>
                                    <StyledChart />
                                    <Router />
                                  </SnackbarProvider>
                                </ThemeLocalization>
                              </ThemeSettings>
                            </ThemeProvider>
                          </MotionLazyContainer>
                        </AclProvider>
                      </BrowserRouter>
                    </SettingsProvider>
                  </LocalizationProvider>
                </PersistGate>
              </ReduxProvider>
            </HelmetProvider>
          </BusinessProvider>
        </AuthProvider>
      </GoogleOAuthProvider>
    </ApolloProvider>
  );
}
