/* eslint-disable no-nested-ternary */
import { forwardRef, useEffect, useState } from 'react';
import { Stack, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

type Props = {
  name: string;
  value?: string | number;
  error?: boolean;
};

const Duration = forwardRef<HTMLDivElement, Props>(({ error, name, value }, ref) => {
  const isDesktop = useResponsive('up', 'sm');

  const { setValue } = useFormContext();

  const [isMounted, setIsMounted] = useState(false);
  const [hoursValue, setHoursValue] = useState<string | number>(0);
  const [minutesValue, setMinutesValue] = useState<string | number>(0);

  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (!isMounted) {
      const durationInSeconds = Number.isNaN(value)
        ? 0
        : typeof value === 'string'
        ? parseInt(value, 10)
        : value;

      const totalMinutes = durationInSeconds ? Math.floor(durationInSeconds / 60) : 0;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      setHoursValue(hours);
      setMinutesValue(minutes);
    }
  }, [isMounted, value]);

  useEffect(() => {
    const h = Number.isNaN(hoursValue) || typeof hoursValue === 'string' ? 0 : hoursValue;
    const m = Number.isNaN(minutesValue) || typeof minutesValue === 'string' ? 0 : minutesValue;
    const secondsValue = h * 60 * 60 + m * 60;
    setValue(name, secondsValue, { shouldTouch: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoursValue, minutesValue]);

  const handleHoursChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value: val } = e.target;

    if (!Number.isNaN(val)) {
      const parsed = val.length > 0 ? parseInt(val, 10) : '';
      setHoursValue(parsed);
    }
  };

  const handleHoursBlur = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value: val } = e.target;
    if (!val || Number.isNaN(val)) {
      setHoursValue(0);
    }
  };

  const handleMinutesChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value: val } = e.target;

    if (!Number.isNaN(val)) {
      const parsed = val.length > 0 ? parseInt(val, 10) : '';
      setMinutesValue(parsed);
    }
  };

  const handleMinutesBlur = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value: val } = e.target;
    if (!val || Number.isNaN(val)) {
      setMinutesValue(0);
    }
  };

  return (
    <Stack
      ref={ref}
      direction={isDesktop ? 'row' : 'column'}
      alignItems={isDesktop ? 'center' : 'flex-start'}
    >
      <TextField
        value={hoursValue}
        onChange={handleHoursChange}
        onBlur={handleHoursBlur}
        error={!!error}
        label="Hours"
        type="number"
        sx={{ marginRight: isDesktop ? 2 : 0, marginBottom: isDesktop ? 0 : 2 }}
      />
      <TextField
        value={minutesValue}
        onChange={handleMinutesChange}
        onBlur={handleMinutesBlur}
        error={!!error}
        label="Minutes"
        type="number"
      />
    </Stack>
  );
});

export default Duration;
