import { useApolloClient } from '@apollo/client';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import axios from '../utils/axios';
import { ActionMapType, AuthStateType, RecordType, SessionContextType } from './types';
import ME from '../graphql/queries/me';
import LOGIN_MUTATION from '../graphql/mutations/login';
import LOGIN_WITH_GOOGLE_MUTATION from '../graphql/mutations/loginWithGoogle';
import SIGNUP_MUTATION from '../graphql/mutations/signup';
import LOGOUT_MUTATION from '../graphql/mutations/logout';
import RESET_PASSWORD_MUTATION from '../graphql/mutations/resetPassword';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  UPDATE_USERS_BUSINESS = 'UPDATE_USERS_BUSINESS',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: RecordType;
  };
  [Types.LOGIN]: {
    user: RecordType;
  };
  [Types.REGISTER]: {
    user: RecordType;
  };
  [Types.UPDATE_USERS_BUSINESS]: {
    businessId: string;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.UPDATE_USERS_BUSINESS) {
    return {
      ...state,
      user: { ...state.user, businessId: action.payload.businessId },
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

export const AuthContext = createContext<SessionContextType | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const client = useApolloClient();

  const initialize = useCallback(async () => {
    try {
      const { data } = await client.query({ query: ME });
      const user = data.me;

      if (user) {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [client]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (email: string, password: string) => {
      const { data } = await client.mutate({
        mutation: LOGIN_MUTATION,
        variables: { email, password },
      });

      const user = data.login;

      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
        },
      });
    },
    [client]
  );

  // LOGIN WITH GOOGLE
  const loginWithGoogle = useCallback(
    async (credential: string) => {
      const { data } = await client.mutate({
        mutation: LOGIN_WITH_GOOGLE_MUTATION,
        variables: { token: credential },
      });

      const user = data.loginWithGoogle;

      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
        },
      });
    },
    [client]
  );

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const { data } = await client.mutate({
        mutation: SIGNUP_MUTATION,
        variables: { email, password, firstName, lastName },
      });

      const user = data.signUp;

      dispatch({
        type: Types.REGISTER,
        payload: {
          user,
        },
      });
    },
    [client]
  );

  // ACCEPT INVITATION AND LOGIN
  const acceptInvitaion = useCallback(
    async (email: string, password: string, firstName: string, lastName: string, token: string) => {
      const response = await axios.post(`/invitations/accept/${token}`, {
        email,
        password,
        firstName,
        lastName,
      });

      const user = response.data;

      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
        },
      });
    },
    []
  );

  // ACCEPT INVITATION WITH GOOGLE
  const acceptInvitationWithGoogle = useCallback(
    async (verificationToken: string, googleToken: string) => {
      const response = await axios.post(`/auth/google/accept-invitation/${verificationToken}`, {
        token: googleToken,
      });
      const user = response.data;

      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    await client.mutate({ mutation: LOGOUT_MUTATION });

    dispatch({
      type: Types.LOGOUT,
    });
  }, [client]);

  // RESET PASSWORD
  const resetPassword = useCallback(
    async (password: string, token: string) => {
      const { data } = await client.mutate({
        mutation: RESET_PASSWORD_MUTATION,
        variables: { token, password },
      });

      const user = data.resetPassword;

      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
        },
      });
    },
    [client]
  );

  const updateUsersBusiness = useCallback((businessId: string) => {
    dispatch({
      type: Types.UPDATE_USERS_BUSINESS,
      payload: {
        businessId,
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'session',
      login,
      register,
      loginWithGoogle,
      acceptInvitaion,
      acceptInvitationWithGoogle,
      updateUsersBusiness,
      logout,
      resetPassword,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      register,
      acceptInvitaion,
      acceptInvitationWithGoogle,
      loginWithGoogle,
      updateUsersBusiness,
      logout,
      resetPassword,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
