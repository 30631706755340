// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
//
import Duration from '../time-range/Duration';

// ----------------------------------------------------------------------

interface Props {
  name: string;
  label?: string;
}

// ----------------------------------------------------------------------

export default function RHFDuraration({ name, label, ...other }: Props) {
  const { control } = useFormContext();

  const labelledby = label ? `${name}-${label}` : '';

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel
              component="legend"
              id={labelledby}
              sx={{ typography: 'body2', color: 'text.secondary', mb: 2 }}
            >
              {label}
            </FormLabel>
          )}

          <Duration error={!!error} {...field} {...other} />

          {!!error && (
            <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
