import React, { createContext, useMemo } from 'react';
import Permission from './Permission';
import UserPermission from './UserPermission';

// @ts-ignore
const Context = createContext<UserPermission>(undefined);

type Props = {
  permission: Permission;
  userPermissions: string[];
  userRole: string;
  children: React.ReactNode;
};

export const Provider = ({
  permission,
  userRole,
  userPermissions,
  children,
}: Props): JSX.Element => {
  const user = useMemo(() => new UserPermission(permission), [permission]);
  user.setPermission(userPermissions);
  user.setRole(userRole);
  return <Context.Provider value={user}>{children}</Context.Provider>;
};

export default Context;
