import { useFormContext, Controller } from 'react-hook-form';
import PhoneInput from 'material-ui-phone-number';
import { TextFieldProps } from '@mui/material';

type Props = TextFieldProps & {
  name: string;
};

const RHFPhoneInput = ({ name, onChange, helperText, ...other }: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <PhoneInput
          {...field}
          {...other}
          defaultCountry="cz"
          onChange={field.onChange}
          fullWidth
          error={!!error}
          helperText={error ? error?.message : helperText}
          variant="outlined"
          autoFormat
        />
      )}
    />
  );
};

export default RHFPhoneInput;
