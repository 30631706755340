import { addSeconds, format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function secondsStringToDate(seconds: string) {
  const date = seconds ? addSeconds(new Date(0), parseInt(seconds, 10)) : new Date();

  return new Date(
    date.toLocaleString('en-US', {
      timeZone: 'GMT',
    })
  );
}

export function secondsStringToTime(seconds: string) {
  return seconds ? new Date(parseInt(seconds, 10) * 1000).toISOString().slice(11, 16) : '';
}

export function getSecondsFromDate(date: Date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = hours * 60 * 60 + minutes * 60;
  return seconds;
}

export function toHoursAndMinutes(totalSeconds: number) {
  const totalMinutes = Math.floor(totalSeconds / 60);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  let res = '';

  if (hours) {
    res += `${hours} ${hours === 1 ? 'hour' : 'hours'} `;
  }

  if (minutes) {
    res += `${minutes} minutes`;
  }

  return res;
}
