import { gql } from '@apollo/client';

export default gql`
  mutation signUp($email: String!, $password: String!, $firstName: String!, $lastName: String!) {
    signUp(email: $email, password: $password, firstName:$firstName, lastName: $lastName) {
      id
      firstName
      lastName
      email
      role
      permissions
      businessId
    }
  }
`;
