export const workingHours = {
  monday: [{ endTime: '64800', startTime: '32400' }],
  tuesday: [{ endTime: '64800', startTime: '32400' }],
  wednesday: [
    { endTime: '48600', startTime: '32400' },
    { endTime: '64800', startTime: '54000' },
  ],
  thursday: [{ endTime: '64800', startTime: '32400' }],
  friday: [{ endTime: '64800', startTime: '32400', timeOff: true }],
  saturday: [],
  sunday: [],
};
