import { gql } from '@apollo/client';

export default gql`
  mutation createBusiness(
    $name: String!
    $domain: String!
    $businessType: String!
    $ownerId: String!
  ) {
    createBusiness(name: $name, domain: $domain, businessType: $businessType, ownerId: $ownerId) {
      id
      name
      domain
    }
  }
`;
