import { gql } from '@apollo/client';

export default gql`
  mutation setBusinessContact($id: String!, $email: String!, $phoneNumber: String) {
    setBusinessContact(id: $id, email: $email, phoneNumber: $phoneNumber) {
      id
      name
      domain
      email
      phoneNumber
    }
  }
`;
