import { gql } from '@apollo/client';

export default gql`
  query getBusiness($id: String!) {
    getBusiness(id: $id) {
      id
      name
      domain
      ownerId
      businessType
      email
      phoneNumber
      about
      logoUrl
      googleAnalyticsId
      facebookUrl
      instagramUrl
      employees {
        id
      }
      locations {
        id
        name
        isDefault
      }
    }
  }
`;
