import { gql } from '@apollo/client';

export default gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      id
      firstName
      lastName
      email
      role
      permissions
      businessId
    }
  }
`;
