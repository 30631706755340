type ResourceOperations = {
  [key: string]: {
    operations?: string[];
  };
};

type Rule = {
  resources: {
    allowed: ResourceOperations;
    denied: ResourceOperations;
  };
};

export type Operation = 'view' | 'create' | 'edit' | 'delete';

export default class Permission {
  permissions: Record<string, Rule> = {};

  resources: string[] = [];

  addPermission(name: string): void {
    if (this.permissions[name]) throw new Error(`Permission ${name} already defined.`);

    this.permissions[name] = {
      resources: {
        allowed: {},
        denied: {},
      },
    };
  }

  addResource(name: string): void {
    if (this.resources.includes(name)) throw new Error(`Resource ${name} already defined.`);
    this.resources.push(name);
  }

  allow(permissionName: string, resourceName: string, operation: Operation): void {
    this.updateResource('allowed', permissionName, resourceName, operation);
  }

  deny(permissionName: string, resourceName: string, operation: Operation): void {
    this.updateResource('denied', permissionName, resourceName, operation);
  }

  isAllowed(userPermissions: string[], resourceName: string, operation: Operation): boolean {
    if (this.getResourceOperations('denied', userPermissions, resourceName).includes(operation))
      return false;
    if (this.getResourceOperations('allowed', userPermissions, resourceName).includes(operation))
      return true;

    return false;
  }

  private getResourceOperations(
    type: 'allowed' | 'denied',
    userPermissions: string[],
    resourceName: string
  ): string[] {
    let result: string[] = [];
    userPermissions.forEach((permissionName) => {
      const operations =
        this.permissions[permissionName]?.resources[type][resourceName]?.operations || [];
      result = [...result, ...operations];
    });
    return result;
  }

  private updateResource(
    type: 'allowed' | 'denied',
    permissionName: string,
    resourceName: string,
    operation: Operation
  ) {
    if (typeof this.permissions[permissionName] === 'undefined')
      throw new Error(`Permission ${permissionName} not found.`);

    if (!this.resources.find((resource) => resource === resourceName))
      throw new Error(`Resource name ${resourceName} not found.`);

    if (!this.permissions[permissionName].resources[type][resourceName]) {
      this.permissions[permissionName].resources[type][resourceName] = {};
    }

    this.permissions[permissionName].resources[type][resourceName].operations = [
      ...(this.permissions[permissionName].resources[type][resourceName].operations || []),
      operation,
    ];
  }
}
